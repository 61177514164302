import React from "react";
import styled from "styled-components";
import logoImg from '../assets/images/logo.png';
function Header() {
  return (
    <HeaderContainer>
     
<Logo>
        <img
          src={logoImg}
          alt="YumScore Logo"
          style={{ height: "80px", width: "auto" }}
        />
      </Logo>
      <Logo>YumScore</Logo>
      <Tagline>Find & rate the best bites around you!</Tagline>
    </HeaderContainer>
  );
}

export default Header;

const HeaderContainer = styled.header`
  padding: 2rem 0;
  background-color: #b22c0a;
  color: white;
`;

const Logo = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`;

const Tagline = styled.p`
  font-size: 1.25rem;
  margin-top: 0.5rem;
`;

//image size i