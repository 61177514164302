import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Features from './components/Features';
import DownloadApp from './components/DownloadApp';

function App() {
  return (
    <MainContainer>
      <Header />
      <Features />
      <DownloadApp />
    </MainContainer>
  );
}

export default App;

const MainContainer = styled.div`
  font-family: 'Arial, sans-serif';
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
  padding: 0 1rem;
`;
