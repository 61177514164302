import React from "react";
import styled from "styled-components";

function DownloadApp() {
  return (
    <DownloadContainer>
      <SectionTitle>Get YumScore Now!</SectionTitle>
      <br></br>
      <Subtitle>Launching Soon!</Subtitle>
      <DownloadButton
        href="https://play.google.com/store/apps/details?id=com.yumscore.app"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download on Play Store
      </DownloadButton>
    </DownloadContainer>
  );
}

export default DownloadApp;

const DownloadContainer = styled.section`
  padding: 2rem 0;
  background-color: #333;
  color: white;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #e0e0e0;
  margin-bottom: 1.5rem;
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 0.8rem 2rem;
  background-color: #b22c0a;
  color: white;
  font-size: 1rem;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e68900;
  }
`;
