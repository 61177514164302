import React from "react";
import styled from "styled-components";

function Features() {
  return (
    <FeaturesContainer>
      <SectionTitle>How It Works</SectionTitle>
      <FeaturesGrid>
        <Feature>
          <FeatureIcon>🔍</FeatureIcon>
          <FeatureTitle>Discover</FeatureTitle>
          <FeatureDesc>Find popular restaurants nearby.</FeatureDesc>
        </Feature>
        <Feature>
          <FeatureIcon>🌟</FeatureIcon>
          <FeatureTitle>Rate</FeatureTitle>
          <FeatureDesc>Rate dishes you try and share reviews.</FeatureDesc>
        </Feature>
        <Feature>
          <FeatureIcon>📷</FeatureIcon>
          <FeatureTitle>Share</FeatureTitle>
          <FeatureDesc>Post photos and videos of your meal.</FeatureDesc>
        </Feature>
      </FeaturesGrid>
    </FeaturesContainer>
  );
}

export default Features;

const FeaturesContainer = styled.section`
  padding: 2rem 0;
  background-color: #fff;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const Feature = styled.div`
  max-width: 180px;
  text-align: center;
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #b22c0a;
`;

const FeatureDesc = styled.p`
  font-size: 0.9rem;
  color: #666;
`;
